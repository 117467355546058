import { forEach } from './util';

const setUncheckedMessage = (el) => {
  el.querySelector('h3').innerHTML = 'Jonger dan 18 jaar';
  el.querySelector('.age-check__content').innerHTML = 'Je hebt aangegeven dat je jonger bent dan 18 jaar en dat is te jong om deze website te bezoeken.';
};

export const enhancer = (el) => {
  if (sessionStorage.getItem("ageCheck") !== 'checked') {
    el.classList.add('age-check--show');
  }

  if (sessionStorage.getItem("ageCheck") == 'unchecked') {
    setUncheckedMessage(el);
  }

  const buttons = el.querySelectorAll('.button');
  forEach(buttons, (button) => {
    button.addEventListener('click', e => {
      const check = button.getAttribute('data-check');
      sessionStorage.setItem("ageCheck", check);
      if (check == 'unchecked') {
        setUncheckedMessage(el);
      } else {
        el.classList.remove('age-check--show');
      }
    });
  });

};
